<template>
  <div id="login">
    <b-form class="lform">
      <b-container>
        <b-row class="">
          <h1 class="titles">Logout</h1>
          <h3>Hello</h3>
          <!-- <p>{{allPosts.title}}</p> -->
        </b-row>
        <b-button @click="userLogout()">LOGOUT</b-button>
      </b-container>
    </b-form>
  </div>
</template>
<script>
// import api from '../router/index'
import { mapActions } from "vuex";

export default {
  data(){
    return{
      allPosts:[]
    }
  },
  methods: {
    ...mapActions(["logout"]),
    userLogout() {
      this.logout();
      this.$router.push("/");
    },
  },
  created(){

  }
};
</script>